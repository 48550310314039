<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The reaction between
        <stemble-latex content="0.066\,\text{g}" />
        of magnesium with excess HBr was carried out in an ice calorimeter as used in this lab. The
        volume of water in the calorimeter was observed to decrease by
        <latex-number number="0.17" unit="\text{mL}" />
        during the reaction. Calculate the enthalpy of reaction,
        <stemble-latex content="$\Delta_\text{r}\text{H,}$" />
        in kJ/mol.
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\Delta_\text{r}\text{H:}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'ChemMcMPL4_Q10',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    magnesium() {
      return this.taskState.getValueBySymbol('magnesium').content;
    },
    volChange() {
      return '0.17'; // Need to calculate what this will actually be for given mass of magnesium
    },
  },
};
</script>
